<template>
  <div
    class="
      container
      mb-4
      text-center-mobile-only text-from-mobile-to-desktop
      p-5
    "
  >
    <div
      class="columns is-desktop is-align-items-center row-reverse-desktop-only"
    >
      <div class="column is-5-desktop">
        <h2 class="title is-4-5 mb-5-5 has-text-weight-medium">
          КАК ПРИНЯТЬ УЧАСТИЕ В ТЕНДЕРАХ?
        </h2>
        <p class="mb-5-8 text-promo pr-6-d">
          Для участия в торгах нужно пройти процедуры регистрации аккредитации, состоящие из нескольких простых этапов. <br>В случае возникновения сложностей, вы можете обратиться в службу поддержки, заполнив форму обратной связи.
        </p>
        <div>
          <router-link
            to="tenders"
            class="button is-normal main-button is-fullwidth-mobile-only"
          >
            <span class="icon is-small">
              <img
                src="../assets/svg/gavel.svg"
                alt="gavel"
                height="17"
                width="17"
              />
            </span>
            <span>ПРИНЯТЬ УЧАСТИЕ</span>
          </router-link>
        </div>
      </div>
      <div class="column is-hidden-mobile is-7-desktop">
        <div class="pl-6 pr-6 pl-0-desktop pr-0-desktop">
          <img src="../assets/img/participation-diagram.png" alt="gavel" />
        </div>
      </div>
      <div
        class="
          column
          is-hidden-tablet
          has-text-left-mobile
          participation-items-list
        "
      >
        <div class="is-flex is-align-items-center participation-list-item">
          <div class="circle-item mr-4">
            <span>1</span>
          </div>
          <span> Регистрация аккаунта <br />пользователя </span>
        </div>
        <div class="is-flex is-align-items-center participation-list-item">
          <div class="circle-item mr-4">
            <span>2</span>
          </div>
          <span>
            Регистрация аккаунта <br />организации <br />пользователя
          </span>
        </div>
        <div class="is-flex is-align-items-center participation-list-item">
          <div class="circle-item mr-4">
            <span>3</span>
          </div>
          <span> Заполнение анкеты <br />организации </span>
        </div>
        <div class="is-flex is-align-items-center participation-list-item">
          <div class="circle-item mr-4">
            <span>4</span>
          </div>
          <span> Аккредитация <br />администратором <br />площадки</span>
        </div>
        <div class="is-flex is-align-items-center participation-list-item">
          <div class="circle-item mr-4">
            <span>5</span>
          </div>
          <span> Доступ к участию <br />в торгах</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TenderParticipation",
};
</script>
