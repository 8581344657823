<template>
  <div
    @mouseover="expandOrCollapse(true)"
    @mouseleave="expandOrCollapse(false)"
  >
    {{ shownText }}
    <br />
    <div class="mt-3" v-if="textIsLong && !showFull">
      <ArrowDownIcon />
    </div>
  </div>
</template>

<script>
import ArrowUpIcon from "@/components/icons/ArrowUpIcon.vue";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";

export default {
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
  },
  props: {
    text: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    showFull: false,
    cutLength: 150,
    hover: false,
  }),
  computed: {
    strippedFromHtmlText() {
      return this.stripHTML(this.text.toString());
    },
    textIsLong() {
      return this.strippedFromHtmlText.length > this.cutLength;
    },
    shownText() {
      if (this.showFull || !this.textIsLong) return this.strippedFromHtmlText;
      return this.strippedFromHtmlText.slice(0, this.cutLength);
    },
  },
  methods: {
    toggleShowMore() {
      this.showFull = !this.showFull;
    },
    expandOrCollapse(expand = true) {
      if (!this.textIsLong) return;
       this.showFull = expand;
    },
  },
};
</script>

<style lang="scss">
</style>