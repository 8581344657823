<template>
  <div class="container mb-4 mt-d-4 text-center-mobile-only p-5">
    <div class="p-5 p-d-15 shadow-d bg-images-hd br-2">
      <div class="columns is-desktop">
        <div class="column is-half">
          <h2 class="title is-2-5 mb-4">
            <span class="text-1 bg-gradient-1">ЕДИНАЯ</span
            ><br class="is-hidden-tablet" />
            ОНЛАЙН-ПЛАТФОРМА<br />
            ДЛЯ ЗАКУПОК
          </h2>
          <p class="mb-6 text-promo">
            Упрощенный доступ к торгам, понятная и прозрачная система закупок,
            эффективная обратная связь
          </p>
          <router-link to="tenders" class="button is-normal main-button">
            <span class="icon is-small">
              <img
                src="../assets/svg/gavel.svg"
                alt="gavel"
                height="17"
                width="17"
              />
            </span>
            <span>ПРИНЯТЬ УЧАСТИЕ</span>
          </router-link>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>
