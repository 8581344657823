<template>
  <section>
    <header-vue />
    <main-banner />
    <trading-direction />
    <tender-list />
    <free-information />
    <partners />
    <tender-participation />
    <faq />
    <footer-vue />
  </section>
</template>

<script>

import HeaderVue from '@/components/common/Header.vue'
import FooterVue from '@/components/common/Footer.vue'
import MainBanner from '@/home/MainBanner.vue'
import TradingDirection from '@/home/TradingDirection.vue'
import TenderList from '@/home/TenderList.vue'
import FreeInformation from '@/home/FreeInformation.vue'
import Partners from '@/home/Partners.vue'
import TenderParticipation from '@/home/TenderParticipation.vue'
import Faq from '@/home/FAQ.vue'

export default {
  name: 'Home',
  components: { 
    HeaderVue, 
    FooterVue, 
    MainBanner,
    TenderParticipation,
    TradingDirection, 
    TenderList, 
    FreeInformation, 
    Partners, 
    Faq 
  },
}
</script>
