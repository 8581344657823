<template>
  <div class="container numbers-section p-5 mb-4">
    <div class="columns is-centered has-text-centered p-5">
      <div class="numbers-section__item column"
        v-for="item in numbersData"
        :key="item.id"
        >
        <img :src="item.img" />
        <p class="number-title">{{ item.number }}</p>
        <div class="number-text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FreeInformation',
  computed: {
    numbersData() {
      return this.$store.getters["freeInformation/list"];
    }
  },
  mounted() {
    this.$store.dispatch('freeInformation/load')
  },
}
</script>
