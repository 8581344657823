<template>
  <div class="container mb-4 pr-3 pl-3 p-5-tablet">
    <h2 class="title is-4-5 mb-5-5 has-text-centered has-text-weight-medium">
      ЧАСТЫЕ ВОПРОСЫ
    </h2>
    
    <div class="shadow-d pt-5-tablet pb-5-tablet pr-6-tablet pl-6-tablet br-3">
      <div class="p-6" v-if="faqLoading"></div>
      <b-loading :active="faqLoading" :is-full-page="false"></b-loading>

      <b-collapse
        class=""
        animation="slide"
        v-for="(collapse, index) of faqData"
        :key="index"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="is-flex is-justify-content-space-between"
            v-bind:class="[
              faqData.length === index + 1 ? '' : 'border-bottom-light',
            ]"
            role="button"
          >
            <p
              class="
                title
                is-6 is-4-2-tablet
                pt-5
                pb-5
                mb-0
                has-text-weight-medium
              "
            >
              {{ collapse.question }}
            </p>
            <a class="card-header-icon p-0">
              <img
                v-if="props.open"
                src="../assets/svg/ion_minus.svg"
                alt="plus"
                class="hw-26 hw-38-tablet"
              />
              <img
                v-else
                src="../assets/svg/ion_plus.svg"
                alt="plus"
                class="hw-26 hw-38-tablet"
              />
            </a>
          </div>
        </template>
        <div class="pt-4 pb-4">
          <div class="content text-promo" v-html="collapse.answer">
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data: () => {
    return {};
  },
  computed: {
    faqData() {
      return this.$store.getters["faq/list"];
    },
    faqLoading() {
      return this.$store.getters["faq/loading"];
    },
  },
  mounted() {
    this.$store.dispatch("faq/load");
  },
};
</script>
